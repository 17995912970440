export const AccountWallet = {
  title: "Mi Cartera",
  buy: "Comprar más créditos",
  tableHeaders: {
    date: "Fecha",
    for: "Concepto",
    used: "Créditos",
    total: "Créditos restantes",
  },
  talbeBody: {
    suscription: "Suscripción a ",
    renewal: "Renovación",
    buy: "Compra de créditos",
    credit: "Crédito",
    credits: "Créditos",
  },
};

export default AccountWallet;
