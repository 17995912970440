export const AccountUser = {
  title: "Mi Perfil",
  info: {
    title: "Mis Datos",
    form: {
      avatar: {
        label: "Foto de perfil",
        add: "Añadir",
        edit: "Editar",
        error: "Error en el formato de imagen. Ha de ser JPG, JPEG o PNG",
      },
      alias: { label: "Alias" },
      name: { label: "Nombre" },
      surname: { label: "Apellidos" },
      email: { label: "Email" },
      phone: { label: "Teléfono" },
      phone2: { label: "Teléfono 2" },
      whatsapp: { label: "Whatsapp" },
      "new-password": {
        label:
          "Nueva contraseña (rellenar solo en caso de querer cambiar la contraseña)",
      },
      postal: { label: "Dirección" },
      submit: { label: "Guardar" },
    },
  },
  subscriptions: {
    title: "Mis Suscripciones",
    tableHeaders: {
      channel: "Canal",
      since: "Suscrito desde",
      expiring: "Vencimiento",
      type: "Tipo",
    },
    talbeBody: {
      month: "mes",
      months: "meses",
      renewable: "renovable",
      monthly: "mensual",
      premium: "Premium",
      actions: {
        renew: "Renovar suscripción",
        autorenew: "Activar autorenovación",
        cancel: "Cancelar autorenovación",
        resub: "Volver a suscribirse",
      },
    },
  },
  messages: {
    success: {
      header: "Datos actualizados",
      content: ["Tus datos se han actualizado correctamente"],
      actions: {
        ok: "Aceptar",
      },
    },
  },
};

export default AccountUser;
