import { React, createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "the-new-css-reset/css/reset.css";
import "semantic-ui-css/semantic.min.css";

import { PathRoutes } from "./data/Data";

import Home from "./pages/Home";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Channels from "./pages/sections/Channels";
import Channel from "./pages/sections/Channel";
import ChannelVideo from "./pages/sections/ChannelVideo";
import ContentCreators from "./pages/sections/ContentCreators";
import About from "./pages/sections/About";
import Platform from "./pages/sections/Platform";
import AccountUser from "./pages/account/AccountUser";
import AccountManager from "./pages/account/AccountManager";
import {
  AccountChannelEdit,
  AccountChannelNew,
} from "./pages/account/AccountChannel";
import SessionLogin from "./pages/session/SessionLogin";
import SessionPasswordRecovery from "./pages/session/SessionPasswordRecovery";
import SessionSignup from "./pages/session/SessionSignup";
import SessionSignupManager from "./pages/session/SessionSignupManager";
import Legal from "./pages/sections/Legal";
import Credits from "./pages/sections/Credits";
import CreditsResult from "./pages/sections/CreditsResult";
import Cookies from "./layout/Cookies";
import Agegate from "./layout/Agegate";
import SessionLogout from "./pages/session/SessionLogout";

export const sessionContext = createContext(null);

export default function App() {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("session-context")) || null
  );

  const updateUserData = (values) => {
    setUserData({ ...userData, ...values });
  };

  return (
    <BrowserRouter>
      <sessionContext.Provider value={userData}>
        <div>
          <Header />
          <Routes>
            <Route
              path={PathRoutes.PATH_LEGAL_LEGALNOTICE}
              element={<Legal section={"legalNotice"} />}
            />
            <Route
              path={PathRoutes.PATH_LEGAL_CANCELLATIONRETURNSANDSHIPPINGPOLICY}
              element={
                <Legal section={"cancellationReturnsAndShippingPolicy"} />
              }
            />
            <Route
              path={PathRoutes.PATH_LEGAL_CONTENTPOLICY}
              element={<Legal section={"contentPolicy"} />}
            />
            <Route
              path={PathRoutes.PATH_LEGAL_COOKIEPOLICY}
              element={<Legal section={"cookiePolicy"} />}
            />
            <Route
              path={PathRoutes.PATH_LEGAL_PRIVACYPOLICY}
              element={<Legal section={"privacyPolicy"} />}
            />
            <Route
              path={PathRoutes.PATH_LEGAL_TERMSOFSERVICE}
              element={<Legal section={"termsOfService"} />}
            />
            <Route path={PathRoutes.PATH_HOME} element={<Home />} />
            <Route path={PathRoutes.PATH_CHANNELS} element={<Channels />} />
            <Route
              path={PathRoutes.PATH_CHANNEL_VIDEO}
              element={<ChannelVideo />}
            />
            <Route path={PathRoutes.PATH_CHANNEL} element={<Channel />} />
            <Route
              path={PathRoutes.PATH_CONTENTCREATORS}
              element={<ContentCreators />}
            />
            <Route path={PathRoutes.PATH_PLATFORM} element={<Platform />} />
            <Route path={PathRoutes.PATH_ABOUT} element={<About />} />
            <Route
              path={PathRoutes.PATH_SESSION_LOGIN_USER}
              element={
                <SessionLogin
                  updateUserData={updateUserData}
                  loginType="user"
                />
              }
            />
            <Route
              path={PathRoutes.PATH_SESSION_LOGIN_MANAGER}
              element={
                <SessionLogin
                  updateUserData={updateUserData}
                  loginType="manager"
                />
              }
            />
            <Route
              path={PathRoutes.PATH_SESSION_PASSWORD_RECOVERY}
              element={<SessionPasswordRecovery />}
            />
            <Route
              path={PathRoutes.PATH_SESSION_LOGOUT}
              element={<SessionLogout updateUserData={updateUserData} />}
            />
            <Route
              path={PathRoutes.PATH_SESSION_SIGNUP_USER}
              element={<SessionSignup />}
            />
            <Route
              path={PathRoutes.PATH_SESSION_SIGNUP_MANAGER}
              element={<SessionSignupManager updateUserData={updateUserData} />}
            />
            <Route
              path={PathRoutes.PATH_ACCOUNT_USER}
              element={<AccountUser />}
            />
            <Route
              path={PathRoutes.PATH_ACCOUNT_MANAGER}
              element={<AccountManager />}
            />
            <Route
              path={PathRoutes.PATH_ACCOUNT_CHANNEL_NEW}
              element={<AccountChannelNew />}
            />
            <Route
              path={PathRoutes.PATH_ACCOUNT_CHANNEL_EDIT}
              element={<AccountChannelEdit />}
            />
            <Route
              path={PathRoutes.PATH_CREDITS_OK}
              element={<CreditsResult error={false} />}
            />
            <Route
              path={PathRoutes.PATH_CREDITS_KO}
              element={<CreditsResult error={true} />}
            />
            <Route path={PathRoutes.PATH_CREDITS} element={<Credits />} />
          </Routes>
          <Footer />
          <Cookies />
          <Agegate />
        </div>
      </sessionContext.Provider>
    </BrowserRouter>
  );
}
