import React from "react";
import propTypes from "prop-types";
import { Icon } from "semantic-ui-react";

const ChannelCard = ({
  slug,
  thumbnail,
  description,
  zone,
  city,
  display_name,
  verified,
  premium,
  audio_clip,
  has_comments,
}) => {
  const video = Math.random() < 0.7 ? "premium" : "";
  console.log("audio_clip", slug, has_comments);
  return (
    <article
      className={`channel-card ${premium ? "premium" : null}`}
      onClick={() => (document.location.href = `/${slug}`)}
    >
      <div className="channel-card-image">
        <div className="channel-card-image-badgets">
          <div className="channel-card-image-badgets-new"></div>
          <div className="channel-card-image-badgets-subscribed"></div>
          <div className="channel-card-image-badgets-hot"></div>
          <div className="channel-card-image-badgets-actress"></div>
        </div>
        <img
          className="channel-card-image-img"
          src={thumbnail}
          alt={description}
          title={slug}
        />
      </div>
      <div className="channel-card-info">
        <div className="channel-card-info-icons">
          {premium ? (
            <Icon className="channel-card-info-icons-premium" name="star" />
          ) : null}
          {verified ? (
            <Icon
              className="channel-card-info-icons-verified"
              name="check circle"
            />
          ) : null}
          {video ? (
            <Icon className="channel-card-info-icons-video" name="play" />
          ) : null}
          {audio_clip ? (
            <Icon className="channel-card-info-icons-clip" name="music" />
          ) : null}
          {has_comments ? (
            <Icon
              className="channel-card-info-icons-comments"
              name="comments"
            />
          ) : null}
        </div>
        <h1 className="channel-card-info-name">{display_name}</h1>
        <p className="channel-card-info-description">{description}</p>
        <div className="channel-card-info-tags">
          <ul className="channel-card-info-tags-list">
            {zone ? (
              <li className="channel-card-info-tags-list-item">{zone}</li>
            ) : null}
            {city ? (
              <li className="channel-card-info-tags-list-item">{city}</li>
            ) : null}
          </ul>
        </div>
        <div className="channel-card-info-actions"></div>
      </div>
    </article>
  );
};
ChannelCard.propTypes = {
  slug: propTypes.string,
  display_name: propTypes.string,
  thumbnail: propTypes.string,
  description: propTypes.string,
  premium: propTypes.bool,
  verified: propTypes.bool,
  audio_clip: propTypes.string,
  has_comments: propTypes.bool,
};

export default ChannelCard;
