import _ from "lodash/fp";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Form,
  FormField,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextArea,
} from "semantic-ui-react";
import { Texts } from "../data/Texts";
import {
  useAudioUpload,
  useMediaDelete,
  useMediaUpload,
} from "../data/api/useMedia";

const AccountChannelGalleryAudio = ({ channel_id, audio_clip, mutate }) => {
  const [audioFile, setAudioFile] = useState({});
  const { trigger } = useAudioUpload(audio_clip?.id);

  useEffect(() => {
    return audio_clip?.url
      ? setAudioFile((prevAudioFile) => ({
          ...prevAudioFile,
          url: `//${audio_clip?.url}`,
        }))
      : setAudioFile((prevAudioFile) => ({ ...prevAudioFile }));
  }, [audio_clip]);

  const handleAudioFileChange = (e, data) => {
    if (!e.target.files[0]) {
      return;
    }

    setAudioFile({
      ...audioFile,
      file: e.target.files[0],
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  const uploadAudio = () => {
    const formData = new FormData();
    formData.append("channel_id", channel_id);
    formData.append("audio", audioFile.file);

    trigger(formData).then((response) => {
      mutate();
    });
  };

  return (
    <>
      <h2>{Texts.account.channel.gallery.audio.title}</h2>

      <Form className="account-channel-gallery-audio">
        {audioFile.url ? (
          <audio width="100%" height="100%" controls key={audioFile.url}>
            <source src={audioFile.url} />
          </audio>
        ) : null}
        <FormField className="account-channel-gallery-audio-field">
          <label>{Texts.account.channel.gallery.audio.fileLabel}</label>
          <Input
            type="file"
            name="audio"
            onChange={handleAudioFileChange}
            accept="audio/*"
          />
        </FormField>
        <FormField className="account-channel-gallery-audio-button">
          <Button
            disabled={!audioFile.file}
            content={Texts.account.channel.gallery.audio.button}
            primary
            onClick={() => uploadAudio()}
          />
        </FormField>
      </Form>
    </>
  );
};

AccountChannelGalleryAudio.propTypes = {
  channel_id: PropTypes.number,
  audio_clip: PropTypes.object,
  mutate: PropTypes.func,
};

const AccountChannelGalleryAddButton = ({ setAddMediaModal }) => {
  return (
    <>
      <Button
        icon="add"
        content={Texts.account.channel.gallery.add}
        labelPosition="left"
        primary
        onClick={() => setAddMediaModal(true)}
      />
    </>
  );
};

AccountChannelGalleryAddButton.propTypes = {
  setAddMediaModal: PropTypes.func,
};

const AccountChannelGalleryModalPreview = ({ type, url }) => {
  return (
    <>
      {_.startsWith("video")(type) ? (
        <video width="100%" height="100%" controls>
          <source src={url} />
        </video>
      ) : _.startsWith("image")(type) ? (
        <img src={url} alt="" />
      ) : null}
    </>
  );
};

AccountChannelGalleryModalPreview.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
};

const AccountChannelGalleryModal = ({
  mediaToEdit,
  setAddMediaModal,
  channel_id,
  mutate,
}) => {
  const [mediaFile, setMediaFile] = useState({});
  const [mediaThumbnail, setMediaThumbnail] = useState({});
  const [mediaInfo, setMediaInfo] = useState({ title: "", description: "" });
  const { trigger } = useMediaUpload(mediaToEdit?.id);

  useEffect(() => {
    if (mediaToEdit) {
      setMediaInfo({
        title: mediaToEdit?.title,
        description: mediaToEdit?.description,
      });
      setMediaFile({
        type: mediaToEdit?.type,
        url: `//${mediaToEdit?.url}`,
      });
      setMediaThumbnail({
        type: "image",
        url: `//${mediaToEdit?.thumbnail?.url}`,
      });
    }
  }, []);

  const handleMediaFileChange = (e, data) => {
    if (!e.target.files[0]) {
      return;
    }

    setMediaFile({
      ...mediaFile,
      file: e.target.files[0],
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0]),
    });
    if (!_.startsWith("video")(mediaFile.type)) {
      setMediaThumbnail({});
    }
  };

  const handleMediaThumbnailChange = (e, data) =>
    setMediaThumbnail({
      ...mediaThumbnail,
      file: e.target.files[0],
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0]),
    });

  const handleMediaInfoChange = (_e, { name, value }) =>
    setMediaInfo({ ...mediaInfo, [name]: value });

  const uploadMedia = () => {
    const formData = new FormData();
    formData.append("title", mediaInfo.title);
    formData.append("description", mediaInfo.description);
    formData.append("channel_id", channel_id);
    formData.append("media", mediaFile.file);
    formData.append("thumbnail", mediaThumbnail.file);

    trigger(formData).then((response) => {
      mutate();
      setAddMediaModal(false);
    });
  };
  return (
    <Modal open onClose={() => setAddMediaModal(false)} closeIcon>
      <ModalHeader>{Texts.account.channel.gallery.modal.title}</ModalHeader>
      <ModalContent>
        <Form>
          <FormField>
            <label>{Texts.account.channel.gallery.modal.titleLabel}</label>
            <Input
              name="title"
              onChange={handleMediaInfoChange}
              value={mediaInfo.title}
            />
          </FormField>
          <FormField>
            <label>{Texts.account.channel.gallery.modal.mediaLabel}</label>
            <Input
              type="file"
              name="media"
              onChange={handleMediaFileChange}
              accept="image/*, video/*"
            />
          </FormField>
          {mediaFile.url && mediaFile.type ? (
            <AccountChannelGalleryModalPreview
              key={mediaFile.url}
              type={mediaFile.type}
              url={mediaFile.url}
            />
          ) : null}
          <FormField>
            <label>
              {Texts.account.channel.gallery.modal.descriptionLabel}
            </label>
            <TextArea
              name="description"
              onChange={handleMediaInfoChange}
              value={mediaInfo.description}
            />
          </FormField>
          {_.startsWith("video")(mediaFile.type) ? (
            <FormField>
              <label>{Texts.account.channel.gallery.modal.thumbLabel}</label>
              <Input
                type="file"
                name="thumbnail"
                onChange={handleMediaThumbnailChange}
                accept="image/*"
              />
            </FormField>
          ) : null}
          {_.startsWith("video")(mediaFile.type) &&
          mediaThumbnail.type &&
          mediaThumbnail.url ? (
            <>
              <AccountChannelGalleryModalPreview
                type={mediaThumbnail.type}
                url={mediaThumbnail.url}
              />
            </>
          ) : null}
          <FormField>
            <Button
              content={Texts.account.channel.gallery.modal.cancel}
              secondary
              onClick={() => setAddMediaModal(false)}
            />
            <Button
              disabled={!mediaFile.file && !mediaToEdit?.id}
              content={Texts.account.channel.gallery.modal.upload}
              primary
              onClick={() => uploadMedia()}
            />
          </FormField>
        </Form>
      </ModalContent>
    </Modal>
  );
};

AccountChannelGalleryModal.propTypes = {
  mediaToEdit: PropTypes.object,
  setAddMediaModal: PropTypes.func,
  channel_id: PropTypes.number,
  mutate: PropTypes.func,
};

const AccountChannelGallery = ({ channel_id, media, audio_clip, mutate }) => {
  const [addMediaModal, setAddMediaModal] = useState(false);
  const [mediaToEdit, setMediaToEdit] = useState(null);
  const { trigger: trigerDelete } = useMediaDelete();

  useEffect(() => {
    if (!addMediaModal) {
      setMediaToEdit(null);
    }
  }, [addMediaModal]);

  const handleOpenEdit = (mediaItem) => {
    setMediaToEdit({ ...mediaItem, id: parseInt(mediaItem.id) });
    setAddMediaModal(true);
  };

  const handleDeleteMedia = (id) => {
    trigerDelete({ id }).then(() => mutate());
  };

  return (
    <div className="account-channel-gallery">
      <AccountChannelGalleryAudio
        channel_id={channel_id}
        audio_clip={audio_clip}
        mutate={mutate}
      />
      <h2>{Texts.account.channel.gallery.title}</h2>
      <AccountChannelGalleryAddButton setAddMediaModal={setAddMediaModal} />
      <Table className="account-channel-gallery-table">
        <TableHeader>
          <TableRow>
            <TableCell>{Texts.account.channel.gallery.table.drag}</TableCell>
            <TableCell>
              {Texts.account.channel.gallery.table.position}
            </TableCell>
            <TableCell>{Texts.account.channel.gallery.table.preview}</TableCell>
            <TableCell>{Texts.account.channel.gallery.table.type}</TableCell>
            <TableCell>
              {Texts.account.channel.gallery.table.exclusive}
            </TableCell>
            <TableCell>{Texts.account.channel.gallery.table.actions}</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody className="account-channel-gallery-table-body">
          {_.map((mediaItem) => {
            const { id, type, url, thumbnail } = mediaItem;
            return (
              <TableRow key={id}>
                <TableCell verticalAlign="middle">
                  <Icon name="grab" />
                </TableCell>
                <TableCell verticalAlign="middle">1</TableCell>
                <TableCell verticalAlign="middle">
                  {type === "image" || thumbnail?.url ? (
                    <img
                      className="account-channel-gallery-table-body-preview"
                      src={
                        type === "image"
                          ? "//" + url
                          : thumbnail
                          ? "//" + thumbnail.url
                          : "https://images.unsplash.com/flagged/photo-1556151994-b611e5ab3675?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2hpY2ElMjBiaWtpbml8ZW58MHx8MHx8fDA%3D"
                      }
                      alt=""
                    />
                  ) : (
                    Texts.account.channel.gallery.table.noThumbnail
                  )}
                </TableCell>
                <TableCell verticalAlign="middle">
                  {Texts.account.channel.gallery.table.types[type]}
                </TableCell>
                <TableCell verticalAlign="middle">
                  <Checkbox />
                </TableCell>
                <TableCell verticalAlign="middle">
                  <ButtonGroup>
                    <Button
                      icon="eye"
                      content={Texts.account.channel.gallery.table.buttons.view}
                      labelPosition="left"
                      secondary
                    />
                    <Button
                      icon="edit"
                      content={Texts.account.channel.gallery.table.buttons.edit}
                      labelPosition="left"
                      secondary
                      onClick={() => handleOpenEdit(mediaItem)}
                    />
                    <Button
                      icon="trash"
                      content={
                        Texts.account.channel.gallery.table.buttons.delete
                      }
                      labelPosition="left"
                      secondary
                      onClick={() => handleDeleteMedia(id)}
                    />
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })(media)}
        </TableBody>
      </Table>
      <AccountChannelGalleryAddButton setAddMediaModal={setAddMediaModal} />
      {addMediaModal ? (
        <AccountChannelGalleryModal
          mediaToEdit={mediaToEdit}
          setAddMediaModal={setAddMediaModal}
          channel_id={channel_id}
          mutate={mutate}
        />
      ) : null}
    </div>
  );
};

AccountChannelGallery.propTypes = {
  channel_id: PropTypes.number,
  media: PropTypes.array,
  audio_clip: PropTypes.object,
  mutate: PropTypes.func,
};

export default AccountChannelGallery;
