import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Label,
  Dropdown,
  TextArea,
  Checkbox,
  Button,
  Icon,
} from "semantic-ui-react";
import { Texts } from "../data/Data";
import propTypes from "prop-types";
import _ from "lodash/fp";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const FormAvatar = ({ fieldData: { name, value, texts, onChange } }) => {
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        const base64Image = await convertBase64(selectedFile);
        onChange(null, { name, value: base64Image });
      } else {
        alert(texts.error);
      }
    }
  };
  return (
    <div className="account-user-avatar">
      <Label>{texts.label}</Label>
      <div className="account-user-avatar-content">
        <div
          className="account-user-avatar-content-avatar"
          onClick={handleClick}
        >
          {value ? (
            <>
              <img
                alt="avatar"
                src={value}
                className="account-user-avatar-content-avatar-image"
              />
              <strong className="account-user-avatar-content-avatar-span">
                {texts.edit}
                <Icon name="edit" />
              </strong>
            </>
          ) : (
            <>
              <Icon
                name="user"
                size="big"
                className="account-user-avatar-content-avatar-icon"
              />
              <strong className="account-user-avatar-content-avatar-span">
                {texts.add}
                <Icon name="plus" />
              </strong>
            </>
          )}
        </div>
      </div>
      <input
        className="account-user-avatar-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormText = ({
  fieldData: {
    name,
    value,
    texts,
    onChange,
    type,
    is_disabled,
    is_required,
    default_value,
  },
}) => {
  return (
    <>
      <Label>{`${texts.label}${is_required ? "*" : ""}`}</Label>
      <Input
        required={is_required}
        id={name}
        type={type ? type : "text"}
        name={name}
        disabled={is_disabled}
        value={value || default_value}
        onChange={onChange}
      />
    </>
  );
};

FormText.propTypes = {
  fieldData: propTypes.object,
};

const FormCheckbox = ({ fieldData: { name, value, texts, onChange } }) => {
  const parsedValue = !!+value;
  return (
    <>
      <Label>{texts.label}</Label>
      <Checkbox
        id={name}
        name={name}
        onChange={onChange}
        checked={parsedValue}
      />
    </>
  );
};

FormCheckbox.propTypes = {
  fieldData: propTypes.object,
};

const FormTextarea = ({
  fieldData: { name, value, texts, onChange, is_required },
}) => {
  return (
    <>
      <Label>{`${texts.label}${is_required ? "*" : ""}`}</Label>
      <TextArea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        // required={is_required}
      />
    </>
  );
};

FormTextarea.propTypes = {
  fieldData: propTypes.object,
};

const FormSelect = ({
  fieldData: { name, value, texts, onChange, multiple, searchable, options },
  provinceCityOptions,
}) => {
  const defaultValue = value ? value : multiple ? [] : undefined;
  const trueOptions = _.flow(
    _.cond([
      [
        (n) => _.isEqual("province")(n),
        () =>
          _.map(({ cpro, provincia }) => ({
            key: cpro + provincia,
            value: cpro,
            text: provincia,
          }))(provinceCityOptions),
      ],
      [
        (n) => _.isEqual("city")(n),
        () =>
          _.map(({ id, cmun, municipio }) => ({
            key: cmun + municipio,
            value: id,
            text: municipio,
          }))(provinceCityOptions),
      ],
      [
        _.stubTrue,
        () =>
          _.map((option) => ({
            key: option,
            value: option,
            text: texts.options[option],
          }))(options),
      ],
    ])
  )(name);

  return (
    <>
      <Label>{texts.label}</Label>
      <Dropdown
        id={name}
        name={name}
        deburr
        fluid
        placeholder={Texts.account.channel.select}
        search={searchable}
        selection
        multiple={multiple}
        clearable={multiple}
        options={trueOptions}
        disabled={_.isEmpty(trueOptions)}
        value={defaultValue}
        onChange={(event, data) => onChange(event, data)}
      />
    </>
  );
};

FormSelect.propTypes = {
  fieldData: propTypes.object,
};

const FormPicture = ({ fieldData: { name, value, ratio, onChange } }) => {
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        const base64Image = await convertBase64(selectedFile);
        onChange(null, { name, value: base64Image });
      } else {
        alert(Texts.account.channel.form.picture.error);
      }
    }
  };
  return (
    <div className={`account-channel-picture`}>
      <div className="account-channel-picture-content">
        <Label>{Texts.account.channel.form.picture[name].label}</Label>
        <div
          className={`account-channel-picture-content-picture ${ratio} ${
            !value ? "add" : ""
          }`}
          onClick={handleClick}
        >
          {value ? (
            <>
              <img
                alt=""
                src={value}
                className="account-channel-picture-content-picture-image"
              />
              <strong className="account-channel-picture-content-picture-span">
                {Texts.account.channel.form.picture.edit}
                <Icon name="edit" />
              </strong>
            </>
          ) : (
            <>
              <Icon
                name="image"
                size="big"
                className="account-channel-picture-content-picture-icon"
              />
              <strong className="account-channel-picture-content-picture-span">
                {Texts.account.channel.form.picture.add}
                <Icon name="plus" />
              </strong>
            </>
          )}
        </div>
      </div>
      <input
        className="account-channel-picture-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

const FormNewPassword = ({
  fieldData: { name, value, texts, onChange, type },
}) => {
  return (
    <>
      <Label>{texts.label}</Label>
      <Input
        id={name}
        type="password"
        name={name}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

const FormHidden = ({
  fieldData: { name, value, texts, onChange, type, is_disabled },
}) => {
  return (
    <>
      <Input
        id={name}
        type="hidden"
        name={name}
        disabled={is_disabled}
        value={value}
      />
    </>
  );
};

FormHidden.propTypes = {
  fieldData: propTypes.object,
};

const FormSubmit = ({ fieldData: { name, texts } }) => {
  return (
    <>
      <Button primary id={name} type="submit" name={name}>
        {texts.label}
      </Button>
    </>
  );
};

FormSubmit.propTypes = {
  fieldData: propTypes.object,
};

const AccountForm = ({
  name,
  formFields,
  values,
  texts,
  onSubmit,
  provincesCities,
}) => {
  const [formValues, setFormValues] = useState(values);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const handleChange = (data) => {
    const { name, value, checked } = data;

    if (name === "province") {
      const { municipios: cities } = provincesCities;
      const filteredCitiesIds = _.flow(
        _.filter(
          ({ id, cpro }) =>
            _.includes(id)(formValues.city) && _.includes(cpro)(value)
        ),
        _.map(_.get("id"))
      )(cities);

      setFormValues((prevValues) => ({
        ...prevValues,
        province: value,
        city: filteredCitiesIds,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value || checked,
      }));
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <Form name={`${name}-form`} onSubmit={handleOnSubmit}>
      {formFields.map((field, k) => {
        const provinceCityOptions = _.cond([
          [_.isEqual("province"), () => provincesCities?.provincia],
          [
            _.isEqual("city"),
            () =>
              _.filter(({ cpro }) => _.includes(cpro)(formValues?.province))(
                provincesCities?.municipios || []
              ),
          ],
          [_.stubTrue, () => field.options],
        ])(field.name);

        const fieldData = {
          ...field,
          texts: texts.form[field.name],
          value: formValues[field.name],
          onChange: (_e, data) => handleChange(data),
        };
        let element = null;
        switch (field.type) {
          case "avatar":
            element = (
              <Form.Field key={k}>
                <FormAvatar fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "text":
          case "number":
          case "tel":
          case "email":
            element = (
              <Form.Field key={k}>
                <FormText fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "checkbox":
            element = (
              <Form.Field key={k}>
                <FormCheckbox fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "textarea":
            element = (
              <Form.Field key={k}>
                <FormTextarea fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "select":
          case "tags":
          case "province":
          case "city":
            element = (
              <Form.Field key={k}>
                <FormSelect
                  fieldData={fieldData}
                  provinceCityOptions={provinceCityOptions}
                  selectedProvince={formValues}
                />
              </Form.Field>
            );
            break;

          case "picture":
            element = (
              <Form.Field key={k}>
                <FormPicture fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "hidden":
            element = <FormHidden key={k} fieldData={fieldData} />;
            break;

          case "submit":
            element = (
              <Form.Field key={k}>
                <FormSubmit fieldData={fieldData} />
              </Form.Field>
            );
            break;

          case "new-password":
            element = (
              <Form.Field key={k}>
                <FormNewPassword fieldData={fieldData} />
              </Form.Field>
            );
            break;

          default:
            console.error(`No Field Format for ${field.type}`);
            element = null;
            break;
        }
        return element;
      })}
    </Form>
  );
};

AccountForm.propTypes = {
  name: propTypes.string,
  formFields: propTypes.array,
  values: propTypes.object,
  onSubmit: propTypes.func,
  labels: propTypes.object,
  provincesCities: propTypes.object,
};

export default AccountForm;
