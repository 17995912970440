import React, { useContext, useState } from "react";
import {
  Container,
  Table,
  TableCell,
  Segment,
  Button,
} from "semantic-ui-react";
import { PathRoutes, Texts } from "../../data/Data";
import AccountForm from "../../components/AccountForm";
import AccountUserForm from "../../data/forms/AccountUserForm";
import { useUser, useUserUpdate } from "../../data/api/useUser";
import { sessionContext } from "../../App";
import MessageModal from "../../components/MessageModal";
import AccountWallet from "../../components/AccountWallet";
import _ from "lodash/fp";
import { Link } from "react-router-dom";
import { useSubscriptionAction } from "../../data/api/useSubscription";

const AccountUser = () => {
  const { alias: id, type: userType } = useContext(sessionContext);
  const { user, mutate } = useUser(id);
  const { trigger } = useUserUpdate(user.id);
  const { trigger: triggerSubscriptionAction } = useSubscriptionAction();
  const [open, setOpen] = useState(false);

  const isAllowed = () => {
    return userType === "manager"
      ? (window.location.href = PathRoutes.PATH_ACCOUNT_MANAGER)
      : null;
  };
  isAllowed();

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const editSubscription = (action, sub_id, channel_id) => {
    const payload = {
      action: action,
      user: id,
      channel_id,
      id: sub_id,
    };

    triggerSubscriptionAction(payload).then((response) => {
      mutate();
    });
  };

  const handleOnSubmit = (values) => {
    trigger(values).then((response) => {
      setOpen(true);
    });
  };

  return userType !== "user" ? null : (
    <Container className="account-user">
      <h2>{Texts.account.user.title}</h2>
      <Segment className="account-user-info">
        <h3>{Texts.account.user.info.title}</h3>
        <AccountForm
          name="account-user"
          formFields={AccountUserForm}
          values={user}
          onSubmit={handleOnSubmit}
          texts={Texts.account.user.info}
        />
      </Segment>
      <MessageModal
        open={open}
        texts={Texts.account.user.messages.success}
        action={() => window.location.reload()}
        onClose={() => window.location.reload()}
      />
      <Segment className="account-user-subscriptions">
        <h3>{Texts.account.user.subscriptions.title}</h3>
        <div className="account-user-subscriptions-table">
          <Table>
            <Table.Header className="account-user-subscriptions-table-header">
              <Table.Row>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.channel}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.since}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.expiring}
                </TableCell>
                <TableCell>
                  {Texts.account.user.subscriptions.tableHeaders.type}
                </TableCell>
                <TableCell></TableCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="account-user-subscriptions-table-body">
              {_.map(
                ({
                  id,
                  id_channel,
                  slug,
                  display_name,
                  thumbnail,
                  fecha_sub,
                  fecha_renovacion,
                  fecha_baja,
                  active,
                }) => (
                  <Table.Row
                    key={id}
                    className={`account-user-subscriptions-table-row ${
                      active === "1" ? "" : "expired"
                    }`}
                  >
                    <TableCell
                      className="account-user-subscriptions-table-row-channel"
                      verticalAlign="middle"
                    >
                      <img
                        className="account-user-subscriptions-table-row-channel-thumbnail"
                        src={thumbnail}
                        alt=""
                      />{" "}
                      <Link to={`/${slug}`}>{display_name}</Link>
                    </TableCell>
                    <TableCell verticalAlign="middle">
                      {formatDate(fecha_sub)}
                    </TableCell>
                    <TableCell verticalAlign="middle">
                      {formatDate(fecha_renovacion || fecha_baja)}
                    </TableCell>
                    <TableCell verticalAlign="middle">Renovable</TableCell>
                    <TableCell
                      className="account-user-subscriptions-table-row-actions"
                      verticalAlign="middle"
                    >
                      {active === "1" && fecha_baja ? (
                        <Button
                          size="tiny"
                          primary
                          content={
                            Texts.account.user.subscriptions.talbeBody.actions
                              .renew
                          }
                          onClick={() =>
                            editSubscription("renew", id, id_channel)
                          }
                        />
                      ) : null}
                      {active === "1" && fecha_baja ? (
                        <Button
                          size="tiny"
                          primary
                          content={
                            Texts.account.user.subscriptions.talbeBody.actions
                              .autorenew
                          }
                          onClick={() =>
                            editSubscription("autorenewal", id, id_channel)
                          }
                        />
                      ) : null}
                      {active === "1" && fecha_renovacion ? (
                        <Button
                          size="tiny"
                          secondary
                          content={
                            Texts.account.user.subscriptions.talbeBody.actions
                              .cancel
                          }
                          onClick={() =>
                            editSubscription("cancel", id, id_channel)
                          }
                        />
                      ) : null}
                      {active === "0" ? (
                        <Button
                          size="tiny"
                          primary
                          content={
                            Texts.account.user.subscriptions.talbeBody.actions
                              .resub
                          }
                          onClick={() =>
                            editSubscription("resub", id, id_channel)
                          }
                        />
                      ) : null}
                    </TableCell>
                  </Table.Row>
                )
              )(user.subscriptions)}
            </Table.Body>
          </Table>
        </div>
      </Segment>
      <AccountWallet alias={id} />
    </Container>
  );
};

export default AccountUser;
